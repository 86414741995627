import Vue from 'vue'
import App from './App.vue'
import router from './router'
import "aos/dist/aos.css"
//import VueMatomo from 'vue-matomo'

Vue.config.productionTip = false

/*
Vue.use(VueMatomo, {
  // Configure your matomo server and site by providing
  host: 'https://analytics.telcovillage.com/piwik',
  siteId: 6,

  // Changes the default .js and .php endpoint's filename
  // Default: 'piwik'
  trackerFileName: 'piwik',

  // Overrides the autogenerated tracker endpoint entirely
  // Default: undefined
  // trackerUrl: 'https://example.com/whatever/endpoint/you/have',

  // Enables automatically registering pageviews on the router
  router: router,

  // Enables link tracking on regular links. Note that this won't
  // work for routing links (ie. internal Vue router links)
  // Default: true
  enableLinkTracking: true,

  // Require consent before sending tracking information to matomo
  // Default: false
  requireConsent: false,

  // Whether to track the initial page view
  // Default: true
  trackInitialView: true,

  // Whether or not to log debug information
  // Default: false
  debug: false
});      
*/

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
