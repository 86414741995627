<template>
  <div class="pre-captcha-wrapper">
    <div class="captcha-wrapper">
        <form id="captcha-form" @submit.prevent="onSubmit" class="frm-sample">
            <div id="status-message"></div>
            <!-- Captcha -->
            <div id="sample-captcha"></div>
            <!-- Captcha -->
            <button type="submit" class="captcha-submit">Submit</button>
        </form>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import EventBus from '@/js/event-bus.js';

export default {
  props: ['formData'],
  methods: {
    onSubmit() {
      // Collect the answer
      const imageInput  = document.getElementById('captcha-image-input');
      const audioInput = document.getElementById('captcha-audio-input');
      let data
      if (imageInput && imageInput.value) {
        data = {[imageInput.name] : imageInput.value}
      } else if (audioInput && audioInput.value) {
        data = {[audioInput.name] : audioInput.value}
      } else {
        // Nothing selected, reject.
        return
      }

      // Get ip address from ipify & then send to verify
      axios
        .get("https://api.ipify.org?format=json")
        .then(response => {
          data.ip = response.data.ip
          data.timestamp = Date.now()
          data.email = this.formData.email
          data.msg = this.formData.message
          // Send to verify
          this.sendToVerify(data);
        })
        .catch( (error) => {
          console.log(`Failed to get ip address: ${error}.`)
          const successful = false
          EventBus.$emit('form-submit', successful)
        })      
    },
    sendToVerify(data) {
      axios({
        method: 'post',
        url: 'https://dot.telcovillage.com/backend/try1',
        data: data,
        withCredentials: true
      })
      .then((response) => {
        if (response.data.valid) {
          const successful = true
          EventBus.$emit('form-submit', successful)
        } else {
          const successful = false
          EventBus.$emit('form-submit', successful)
        }
      })
      .catch( (error) => {
        console.log(`Failed to send form data: ${error}`)
        const successful = false
        EventBus.$emit('form-submit', successful)
      });
    }
  }
}
</script>

