<template>
  <div v-if="showCookiesConsent" class="consent-bar-bg">
    <div class="consent-bar">
      <h3>Cookie Disclosure</h3>
      <p>This website uses cookies to provide the best possible functionality. Your navigation on our websites indicates that you accept the use of cookies. You can disable the use of cookies by changing the relevant settings in your browser.</p>
      <div class="button-group">
        <a class="button-more" href="https://www.telcovillage.com/gdpr/TV_Impressum_GDPR.pdf" target="_blank">More</a>
        <a class="button-ok" @click.prevent="allowCookies">OK</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'consent-bar',
  data: function () {
    return {
      showCookiesConsent: localStorage.getItem('cookiesAllowed') != 1
    }
  },
  methods: {
    allowCookies() {
      try {
        localStorage.setItem('cookiesAllowed', 1);
      } catch (e) {
        console.log(e);
      }
      this.showCookiesConsent = false;
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/settings.scss";
  .consent-bar-bg {
    position: fixed;
    z-index: 10;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(black, 0.6);
  }
  .consent-bar {
    width: 70%;
    max-width: 749px;
    padding: 23px 44px;
    background-color: #fff;
    box-shadow: 0 30px 44px 0 rgba(0, 0, 0, 0.5);
    h3 {
      margin-top: 57px;
      margin-bottom: 12px;
    }
    p {
      @include body1($text-gray, 400);
      margin: 0 0 31px;
    }
    .button-group {
      width: 100%;
      height: 42px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .button-more {
        font-family: $helvetica;
        color: $red;
        font-size: 15px;
        font-weight: bold;
        margin-right: 48px;
      }
      .button-ok {
        text-align: center;
        @include red-button();
        width: 82px;
        height: 42px;
        line-height: 42px; // To center the text vertically
        cursor: pointer;
      }
    }
    @media (max-width: $width-tablet) {
      padding: 24px;
      h3 {
        margin-top: 27px;
      }
    }
  }
</style>
