<template>

  <div class="home">
    <!-- CookieConsent -->
    <transition name="fade"><CookieConsent/></transition>
	 
    <!-- Header -->
    <header ref="header">
    <div class="section" style="display: flex;
					height: 60px;
					justify-content: flex-start;
					-webkit-box-align: center;
					align-items: center;
					background-color: #ffffff;">

      <div class="content-group">
        <a href="/">
          <img class="logo" src="@/assets/nav_logo.svg" alt="logo">
        </a>
        <div class="link-group">
          <a
            href="#telcovillage"
            @click.prevent="onClickMenuItem($event, false)"
            :class="{ 'active-menu': activeSectionID === 'telcovillage' }"
          >About TelcoVillage</a>
          <a
            href="#verticals"
            @click.prevent="onClickMenuItem($event, false)"
            :class="{ 'active-menu': activeSectionID === 'verticals' }"
          >IoT - Verticals</a>
          <a
            href="#device"
            @click.prevent="onClickMenuItem($event, false)"
            :class="{ 'active-menu': activeSectionID === 'device' }"
          >IoT Device</a>
          <a
            href="#apps"
            @click.prevent="onClickMenuItem($event, false)"
            :class="{ 'active-menu': activeSectionID === 'apps' }"
          >IoT Apps</a>
        </div>
        <a class="button-contact" style="background-color: #fff;color: #000;" href="#contact" @click.prevent="onClickMenuItem($event, false)">CONTACT</a>
        <button :class="['button-menu', { 'menu-opened': menuOpened }]" @click="onClickMenu">
          <img src="@/assets/nav_menu.svg" alt="menu">
        </button>

        <transition name="fade">
          <div v-if="menuOpened" class="menu-modal">
            <a
              href="#telcovillage"
              @click.prevent="onClickMenuItem($event, true)"
              :class="{ 'active-menu': activeSectionID === 'telcovillage' }"
            >About TelcoVillage</a>
            <a
              href="#verticals"
              @click.prevent="onClickMenuItem($event, true)"
              :class="{ 'active-menu': activeSectionID === 'verticals' }"
            >IoT - Verticals</a>
            <a
              href="#device"
              @click.prevent="onClickMenuItem($event, true)"
              :class="{ 'active-menu': activeSectionID === 'device' }"
            >IoT Device</a>
            <a
              href="#apps"
              @click.prevent="onClickMenuItem($event, true)"
              :class="{ 'active-menu': activeSectionID === 'apps' }"
            >IoT Apps</a>
            <a
              class="menu-contact"
              href="#contact"
              @click.prevent="onClickMenuItem($event, true)"
            >CONTACT</a>
          </div>
        </transition>
      </div>
    </header>
    <main>
      <!-- Section-Hero -->
      <section class="section-hero">
        <h1>IoT Made Simple</h1>
        <h4>
          IoT solutions for Mobile Network Operators.
          <br>Straightforward subscription based products for consumers and industrial use cases.
        </h4>
        <div class="feature-group">
          <div>
            <img src="@/assets/feature_devices.svg" alt="Multifunctional IoT Devices">
            <h6>Multifunctional IoT Devices</h6>
          </div>
          <div>
            <img src="@/assets/feature_platform.svg" alt="IoT Platform Integration">
            <h6>IoT Platform Integration</h6>
          </div>
          <div>
            <img src="@/assets/feature_apps.svg" alt="IoT Mobile & Web APPs">
            <h6>IoT Mobile & Web APPs</h6>
          </div>
        </div>
      </section>

      <!-- Section-TelcoVillage -->
      <section id="telcovillage" class="section-telcovillage">
        <p>About Telcovillage</p>
        <p>
          We partner with telco businesses to fuel innovation with scalable hardware and software solutions in the IoT space.
          <br>
          <br>With over a decade of experience in bridging technologies, switch partitioning as a service and smart devices,
          we offer our expertise to help you uncover the right commercial propositions for your business to reach new markets.
        </p>
      </section>

      <!-- Section-Solution -->
      <section class="section-solution" data-aos="fade-up" data-aos-anchor-placement="top-center" data-aos-once="true">
        <h2>IoT Solution for Cellular Networks</h2>
        <div class="content-group">
          <button @click="onSelectSolution(false)" :class="{'animated pulse active-selection' : !fastImpleSelected}">
            <img src="@/assets/solution_future.svg" alt="future proof">
            <h5>
              Future Proof
              <br>Connectivity Hardware
            </h5>
            <img src="@/assets/solution_disclosure.svg" alt="disclosure">
          </button>
          <button @click="onSelectSolution(true)" :class="{'animated pulse active-selection' : fastImpleSelected}">
            <img src="@/assets/solution_fast.svg" alt="fast implementation">
            <h5>Speed-up IoT Projects</h5>
            <img src="@/assets/solution_disclosure.svg" alt="disclosure">
          </button>
          <p>{{theSolution.first}}</p>
          <p>{{theSolution.second}}</p>
        </div>
      </section>

      <!-- Section-Verticals -->
      <section id="verticals" class="section-verticals" data-aos="fade-up" data-aos-anchor-placement="top-center" data-aos-once="true">
        <h2>Shared capabilities for IoT verticals</h2>
        <h2>IoT Solution for Cellular Networks</h2>
        <h4>A single IoT Solution to serve multiple requirements.</h4>
        <div class="content-group">
          <div class="feature-group">
            <div>
              <img src="@/assets/verticals_agri.svg" alt="Agriculture">
              <p>Agriculture</p>
            </div>
            <div>
              <img src="@/assets/verticals_business.svg" alt="Business Intelligence">
              <p>Business Intelligence</p>
            </div>
            <div>
              <img src="@/assets/verticals_industrial.svg" alt="Industrial">
              <p>Industrial</p>
            </div>
            <div>
              <img src="@/assets/verticals_oil.svg" alt="Oil & Gas">
              <p>Oil & Gas</p>
            </div>
            <div>
              <img src="@/assets/verticals_city.svg" alt="Smart City">
              <p>Smart City</p>
            </div>
            <div>
              <img src="@/assets/verticals_transportation.svg" alt="Transportation & Logistics">
              <p>Transportation & Logistics</p>
            </div>
          </div>
          <div class="text-group">
            <h6>USE CASE AGNOSTIC</h6>
            <h3>Multifunctional IoT Hardware</h3>
            <p>
              Complexity and multi-faceted requirements make serving IoT verticals incredibly challenging for mobile operators.
              <br>
              <br>TelcoVillage IoT hardware streamlines its implementation while reducing time to market, increasing efficiency and cutting costs.
            </p>
          </div>
        </div>
        <h4>Unleash the Power of Telecomunication</h4>
      </section>

      <!-- Section-Device -->
      <section id="device" class="section-device">
        <div id="device-content">
          <h1 id="device-title" data-aos="fade-up" data-aos-anchor-placement="top-center" data-aos-offset="1850" data-aos-once="true">Multifunctional IoT Hardware</h1>
          <h1 id="title-mobile">Multifunctional IoT Hardware</h1>
          <div id="sprite"></div>
          <img src="@/assets/device.png" alt="device">
          <div id="device-feature-group" class="feature-group">
            <div class="device-feature-item">
              <h1>
                Extensive
                <br>Network
                <br>Support
              </h1>
              <div class="right-group">
                <img src="@/assets/device_network.svg" alt="network">
                <h1>EXTENSIVE NETWORK SUPPORT</h1>
                <h3>2G, LTE Cat M1, NB-IoT</h3>
                <p>The cellular technology supports all network infrastructures for IoT. Its custom designed, flexible, on board antennas support a wide range of freque
ncy bands on a worldwide scale.</p>
              </div>
            </div>
            <div class="device-feature-item">
              <h1>
                Sophisticated
                <br>Power Supply
              </h1>
              <div class="right-group">
                <img src="@/assets/device_power.svg" alt="power">
                <h1>SOPHISTICATED POWER SUPPLY</h1>
                <h3>
                  650mAh Lithium Polymer
                  <br>Battery with
                  <br>USB-C Connector
                </h3>
                <p>The powerful internal battery allows cable-free power on the go. The device is equipped with a state-of-the-art USB-C Connector to allow plug-and-pla
y, ongoing power supply from any outlet.</p>
              </div>
            </div>
            <div class="device-feature-item">
              <h1>Minimalistic<br>Design</h1>
              <div class="right-group">
                <img src="@/assets/device_design.svg" alt="design">
                <h1>MINIMALISTIC DESIGN</h1>
				                <h3>Ultra Small,<br>Ultra Light-weight<br>&lt;50mm &lt;50g
                </h3>
                <p>A small and light-weight device built for mobility.</p>
              </div>
            </div>
            <div class="device-feature-item">
              <h1>Multi-Touch<br>User Interface</h1>
              <div class="right-group">
                <img src="@/assets/device_touch.svg" alt="multi touch">
                <h1>MULTI-TOUCH USER INTERFACE</h1>
                <h3>Capacitive Touch area<br>with RGB LEDs</h3>
                <p>The Capacitive Touch Area enables gestural user management of the device. The color coded, nine RGB LEDs report on a number of indicators such as Bat
tery Capacity, Network Status and Air Quality.</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- Section-Apps -->
      <section id="apps" class="section-apps">
        <div class="content-group" data-aos="fade-up" data-aos-anchor-placement="top-center" data-aos-once="true">
          <h2>IoT-Platform agnostic sensors for seamless integration</h2>
          <!-- platform -->
          <hr>
          <div class="platform">
            <div class="feature-group">
              <h6>IoT Platform</h6>
              <img src="@/assets/integration_mqtt.svg" alt="mqtt">
              <img src="@/assets/integration_api.svg" alt="api">
              <img src="@/assets/integration_sql.svg" alt="sql">
            </div>
            <div class="text-group">
              <h3>Platform as a Service (PaaS)</h3>
              <p>
                Our service integrates seamlessly with cellular networks operating their own IoT Platform.
                <br>
                <br>Our IoT Platform as a Service is instantly available to equip you with an intuitive interface and depth communication protocols.
              </p>
            </div>
          </div>

          <!-- apps -->
          <hr>
          <div class="apps">
            <div class="feature-group">
              <h6>Web & Mobile APPs</h6>
              <img src="@/assets/integration_android.svg" alt="android">
              <img src="@/assets/integration_web.svg" alt="web">
              <img src="@/assets/integration_ios.svg" alt="ios">
            </div>
            <div class="text-group">
              <h3>On Demand Software Development</h3>
              <p>We analyse your company use cases to define all custom software development requirements, for Android, Web and iOS.</p>
            </div>
          </div>
        </div>
      </section>
      <!-- Section-Contact -->
      <section id="contact" class="section-contact">
        <div class="content-group">
          <div class="text-group">
            <h2>Get in touch with our team</h2>
            <p>TelcoVillage products are provided by country-based mobile operators.<br><br>Get in touch with us to find out which operator serves your country.</p>
            <p>TelcoVillage products are provided by country-based mobile operators.<br>Get in touch with us to find out which operator serves your country.</p>
          </div>
          <form v-on:submit.prevent="onSubmit" novalidate>
            <label for="email">E-mail</label>
            <input :class="isEmailValid()" type="email" id="email" name="email" autocomplete="email" v-model="form.email" required>
            <label for="message">Message</label>
            <textarea id="message" name="message" v-model="form.message" required></textarea>
            <button type="submit" :disabled="!isValidEmail || form.message == ''">SUBMIT</button>
            <p>For information on our privacy practices and commitment to protecting your privacy, please review our Privacy Policy.</p>
          </form>
        </div>
      </section>
      <transition name="fade">
        <div v-if="showCaptcha" class="captcha-bg" @click.self="onCloseCaptcha">
          <Captcha :form-data="form"/>
        </div>
      </transition>

      <!-- Thanks-Modal -->
      <transition name="fade">
        <div v-if="showThanks" class="thanks-modal-bg" @click.self="onCloseThanks">
          <div class="thanks-modal">
            <button @click="onCloseThanks">
              <img src="@/assets/contact_close.svg" alt>
            </button>
            <h2>{{successfulResponse ? 'Thanks for your message' : 'Oops, something went wrong!'}}</h2>
            <p>{{successfulResponse ? 'Our country representative will contact you soon' : 'Please close the window and try again.'}}</p>
          </div>
        </div>
      </transition>
    </main>

    <!-- Footer -->
    <footer>
      <div class="footer-content">
        <div class="icon-group">
          <img src="@/assets/footer_logo.svg" alt="logo">
          <!-- <p>TelcoVillage</p> -->
          <p>&nbsp;</p>
          <p>Discover us on</p>
          <a href="https://www.linkedin.com/company/telcovillage---the-connection-factory" target="_blank"><img src="@/assets/footer_linkedin.svg" alt="LinkedIn"></a>
        </div>
        <div class="info-group">
          <p>Hennigsdorfer Strasse 126<br>13503 Berlin<br>Germany</p>
          <p>Telephone: <a href="tel:+4930221790000">+49 30 22 179 00 00</a><br>Email:<a href="mailto:info@telcovillage.com">info@telcovillage.com</a></p>
          <p>Registration Court: BERLIN Charlottenburg<br>Registration Number: HRB 148195B<br>VAT ID Number: DE243398656</p>
          <p><strong><a href="https://www.telcovillage.com/gdpr/TV_Impressum_GDPR.pdf" target="_blank">Imprint - Privacy Policy</a></strong><br><br>&copy;TelcoVillage GmbH - All Right Reserved</p>
        </div>
      </div>
    </footer>
  </div>
</template>
<script>
import $ from "jquery";
import AOS from "aos";
import Captcha from "@/components/Captcha.vue";
import CookieConsent from "@/components/CookieConsent.vue"
import EventBus from '@/js/event-bus.js';
AOS.init();
const captchaScriptElementId = 'visualcaptcha-main-script'
const spriteFrameHeight = 800;
const spriteFrameWidth = 800;
const numberOfSpriteFramePerRow = 57;
const numberOfSpriteColumns = 2;
const spriteRate = 20; // lower numbers = faster playback
const minWindowHeightForSprite = 760; // need to sync with $min-window-height-for-sprite in settings.scss
const minWindowWidthForSprite = 767; // need to sync with $minWidth-sprite in settings.scss
export default {
  name: "home",
  components: {
    Captcha,
    CookieConsent
  },
  data() {
   return {
      menuOpened: false,
      fastImpleSelected: false,
      activeSectionID: "", // "telcovillage", "verticals", "device", "apps", ""
      solutions: [
        {
          first:
            "As IoT migrates from retiring 2G to low powered LTE-Cat M1 and NB-IoT networks, Mobile Networks Operators are faced with a tough choice when choosing IoT hardware.",
          second:
            "TelcoVillage provides a future proof IoT solution for 2G, LTE Cat M1 and NB-IoT, a single Multisensor device to support and scale the vast majority of B2B and B2C use cases."
        },
        {
          first:
            "Serving new projects with multiple IoT-Sensors requires lengthy on boarding, evaluation, testing and implementation. When time to market is paramount, businesses can’t afford delays.",
          second:
            "As a one-device solution for multiple use cases, TelcoVillage IoT-Multisensor helps businesses shorten project implementation and enables them to reach new markets in a fraction of time."
        }
      ],
      reg: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      isValidEmail: false,
      form: { email: "", message: "" },
      showThanks: false,
      showCaptcha: false,
      successfulResponse: false
    };
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("resize", this.handleResize);
  },
  mounted() {
    this.$nextTick(() => {
      this.updateDeviceSectionHeight();
      this.setFeatureItemsOffset(this.scrollTop(), document.getElementById("device").offsetTop);
    });
    EventBus.$on('form-submit', successful => {
      this.onResponse(successful)
    });
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("resize", this.handleResize);
  },
  computed: {
    theSolution: function() {
      const index = this.fastImpleSelected ? 1 : 0;
      return this.solutions[index];
    },
    topMenuHeight: function() {
      return this.$refs.header.offsetHeight;
    },
    spriteSpaceHeight: function() {
      return numberOfSpriteFramePerRow * numberOfSpriteColumns * spriteRate;
    }
  },
  methods: {
    scrollTop: function() {
      return $(window).scrollTop() + this.topMenuHeight + 1;
    },
    updateSpriteXPosition: function() {
      const scrollTop = this.scrollTop();
      const deviceTop = document.getElementById("device").offsetTop;
      const offset = scrollTop - deviceTop;
      const frameNumber = Math.round(offset / spriteRate);
      var xPosition = 0
      if (frameNumber >= numberOfSpriteFramePerRow) { xPosition = -spriteFrameWidth }

      // Compensate sprite image when screen width is less than image's width
      if (window.innerWidth < spriteFrameWidth) { xPosition -= (spriteFrameWidth - window.innerWidth) / 2 }
      document.getElementById("sprite").style.backgroundPositionX = `${xPosition}px`;
    },
    updateSpriteYPosition: function(scrollTop, deviceTop) {
      const offset = scrollTop - deviceTop;
      var frameNumber = Math.round(offset / spriteRate);
      const maxFrameNumber = numberOfSpriteFramePerRow * numberOfSpriteColumns;

      // Adjust frameNumber
      if (frameNumber < 0) { frameNumber = 0; }
      if (frameNumber >= numberOfSpriteFramePerRow) {
        if (frameNumber >= maxFrameNumber - 1) {
          frameNumber = numberOfSpriteFramePerRow - 1;
        } else {
          frameNumber -= (numberOfSpriteFramePerRow - 1)
        }
      }
      const yPosition = frameNumber * spriteFrameHeight;
      document.getElementById("sprite").style.backgroundPositionY = `-${yPosition}px`;
    },
    updateDeviceSectionHeight: function() {
      const deviceSection = document.getElementById("device");
      if (
        window.innerWidth > minWindowWidthForSprite &&
        window.innerHeight > minWindowHeightForSprite
      ) {
        const deviceContent = document.getElementById("device-content");
        const padding = 800;
        const extra = this.featureHeight() + this.spriteSpaceHeight + padding;
        deviceSection.style.height = `${deviceContent.offsetHeight + extra}px`;
      } else {
        deviceSection.removeAttribute("style");
      }
      AOS.refresh();
    },
    onClickMenu: function() {
      this.menuOpened = !this.menuOpened;
    },
    onClickMenuItem: function(e, fromModal) {
      var sectionID = e.target.getAttribute("href"),
        theSection = $(sectionID)[0],
        offsetTop = theSection.offsetTop - this.topMenuHeight + 1; // + 1 to make sure it scrolled into the section

      if (fromModal) {
        this.menuOpened = false;
      }

      $("html, body").stop().animate({scrollTop: offsetTop},200);
    },
    handleResize: function() {
      this.updateDeviceSectionHeight();
      this.setFeatureItemsOffset(
        this.scrollTop(),
        document.getElementById("device").offsetTop
      );
      this.updateSpriteXPosition()
    },
    handleScroll: function() {
      var scrollTop = this.scrollTop();
      var deviceTop = document.getElementById("device").offsetTop;
      var appTop = document.getElementById("apps").offsetTop;

      // Set Active Section
      this.setActiveSectionID(scrollTop, deviceTop, appTop);

      // Control Sprite Frames
      window.requestAnimationFrame(() => {
        this.updateSpriteXPosition()
        this.updateSpriteYPosition(scrollTop, deviceTop)
      })

      // Move Features
      this.setFeatureItemsOffset(scrollTop, deviceTop);
    },
    setActiveSectionID: function(scrollTop, deviceTop, appTop) {
      // Decide which section is active, order matters
      this.activeSectionID = ""; // reset every time

      // TelcoVillage
      if (scrollTop > document.getElementById("telcovillage").offsetTop) {
        this.activeSectionID = "telcovillage";
      }

      // Verticals
      if (scrollTop > document.getElementById("verticals").offsetTop) {
        this.activeSectionID = "verticals";
      }

      // Device
      if (scrollTop >= deviceTop) {
        this.activeSectionID = "device";
      }

      // Apps: appTop is possible to have 0 value because it will be hidden in smaller screen
      if (appTop > 0 && scrollTop > appTop) {
        this.activeSectionID = "apps";
      }

      // Contact: Also reach contact section if user has scrolled to the bottom of the page
      if (
        scrollTop > document.getElementById("contact").offsetTop ||
        $(window).scrollTop() + $(window).height() > $(document).height() - 100
      ) {
        this.activeSectionID = "";
      }
    },
    featureHeight: function() {
      const deviceFeatureItems = document.getElementsByClassName(
        "device-feature-item"
      );
      const featureContentHeights = Array.prototype.map.call(
        deviceFeatureItems,
        function(item) {
          return item.offsetHeight;
        }
      );
      return featureContentHeights.reduce((a, b) => a + b, 0);
    },
    setFeatureItemsOffset: function(scrollTop, deviceTop) {
      var items = document.getElementsByClassName("device-feature-item");
      if (
        window.innerWidth > minWindowWidthForSprite &&
        window.innerHeight > minWindowHeightForSprite
      ) {
        const maskPadding = 20;
        const scrollHeight = this.featureHeight() - items[items.length - 1].offsetHeight - maskPadding;

        // Calculate diff
        const featureGroupHeight = document.getElementById(
          "device-feature-group"
        ).offsetHeight;
        const startPoint = deviceTop + this.spriteSpaceHeight;
        var diff = featureGroupHeight - (scrollTop - startPoint);
        if (diff >= featureGroupHeight) {
          diff = featureGroupHeight;
        }
        if (diff < -scrollHeight) {
          diff = -scrollHeight;
        }
        for (var i = 0; i < items.length; i++) {
          items[i].style.transform = `translate(0px, ${diff}px)`;
        }
      } else {
        for (var j = 0; j < items.length; j++) {
          items[j].removeAttribute("style");
        }
      }
    },
    onSelectSolution: function(fastImpleSelected) {
      this.fastImpleSelected = fastImpleSelected;
    },
    isEmailValid: function() {
      if (this.form.email == "") {
        this.isValidEmail = false;
        return "";
      } else {
        const isValid = this.reg.test(this.form.email);
        this.isValidEmail = isValid;
        return isValid ? "has-success" : "has-error";
      }
    },
    onSubmit() {
      this.showCaptcha = true
      let captcha = document.createElement('script')
      captcha.id = captchaScriptElementId
      captcha.setAttribute('src', 'visualcaptcha/main.js')
      document.body.appendChild(captcha)
    },
    onResponse(successful) {
      this.successfulResponse = successful
      this.onCloseCaptcha()
      this.showThanks = true
      if (successful) {
        this.form.email = ""
        this.form.message = ""
      }
    },
    onCloseCaptcha() {
      let captcha = document.getElementById(captchaScriptElementId)
      document.body.removeChild(captcha)
      this.showCaptcha = false
    },
    onCloseThanks() {
      this.showThanks = false;
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/styles/settings.scss";
.home {
  main {
    width: 100%;
  }
  // Header
  header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    width: 100%;
    z-index: 5;
    .content-group {
      width: 100%;
      max-width: 1436px;
      margin: 0 auto;
      display: grid;
      grid-template-columns: 1fr auto 1fr;
      grid-template-rows: $header-height auto;
      grid-template-areas:
        "logo menu button"
        "modal modal modal";
      align-items: center;
      font-weight: bold;
      .logo {
        margin-left: $general-side-padding;
        margin-right: 68px;
      }
      .link-group,
      .menu-modal {
        .active-menu {
          color: $red;
        }
      }
      .link-group {
        display: flex;
        a {
          font-size: 15px;
          color: rgba(#4a4a4a, 0.7);
          margin: 0 10px;
        }
      }
      .button-contact,
      .button-menu {
        justify-self: end;
        width: 100%;
        max-width: 273px;
        min-width: 88px;
        height: $header-height;
        @include red-button();
      }
      .button-contact {
        text-align: center;
        line-height: $header-height; // To center the text vertically
        &:hover {
          background-color: #cc3240;
        }
        &:active {
          background-color: #992530;
        }
      }
      .button-menu {
        &:active {
          background-color: #992530;
        }
        display: none;
        padding: 0;
        img {
          margin-left: 34px;
        }
      }
      .menu-modal {
        display: none;
        grid-area: modal;
        box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.5);
        a {
          font-size: 15px;
          color: rgba(#4a4a4a, 0.7);
        }
        .menu-contact {
          color: $red;
        }
      }
    }
    @media (max-width: $width-tablet) {
      .content-group {
        grid-template-columns: 1fr 1fr;
        grid-template-areas:
          "logo . button"
          "modal modal modal";
           z-index: 99999;
    	   background-color: #fffffd;
        .link-group,
        .button-contact {
          display: none;
        }
        .button-menu {
          display: flex;
          img {
            transition-duration: 0.2s;
            transition-property: transform;
          }
        }
        .menu-opened {
          img {
            transform: rotate(90deg);
          }
        }
        .menu-modal {
          height: 432px;
          max-height: calc(100vh - #{$header-height} - 20px);
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: space-between;
          padding: 80px 0 80px $general-side-padding;
        }
      }
    }
    @media (max-width: $width-phone) {
      .content-group {
        .logo {
          margin-left: $phone-side-padding;
        }
        .button-menu {
          justify-content: center;
          img {
            margin: 0;
            margin-top: 20px;
            
          }
        }
        .menu-modal {
          height: 287px;
          padding: 40px 0 40px $phone-side-padding;
          a:nth-of-type(4) {
            display: none;
          }
        }
      }
    }
    @media (max-height: 492px) {
      .content-group .menu-modal {
        padding-top: 40px;
        padding-bottom: 40px;
      }
    }
  }
  main {
    margin-top: 124px + $header-height;
    @media (max-width: $width-phone) {
      margin-top: 40px + $header-height;
    }
  }
  // Section-Hero
  .section-hero {
    margin: 0 0 $section-margin;
    display: flex;
    flex-direction: column;
    align-items: center;
    h1 {
      @include h1();
      margin: 0;
    }
    h4 {
      text-align: center;
      margin: 32px 0 96px;
    }
    .feature-group {
      display: flex;
      div {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 45px 0;
        h6 {
          color: $text-black;
          text-align: center;
        }
      }
      div:nth-of-type(1) {
        padding-top: 5px;
        h6 {
          margin-top: 13px;
        }
      }
      div:nth-of-type(2) > h6,
      div:nth-of-type(3) > h6 {
        margin-top: 8px;
      }
    }
    @media (max-width: $width-tablet) {
      .feature-group {
        div {
          margin: 0 15px 0;
        }
      }
    }
    @media (max-width: $width-phone) {
      margin-bottom: $section-margin-phone;
      align-items: flex-start;
      h4 {
        text-align: left;
        margin: 32px 0 0;
      }
      .feature-group {
        display: none;
      }
    }
  }
  // Section-TelcoVillage
  .section-telcovillage {
    display: flex;
    max-width: 800px;
    margin: $section-margin auto;
    background-color: #bfbfbf;
    border-radius: 4px;
    padding: 24px 20px;
    box-sizing: border-box;
    p:nth-of-type(1) {
      @include body1(white, 700);
      margin: 0 88px 20px 0;
    }
    p:nth-of-type(2) {
      @include body1(#404040, 400);
      max-width: 517px;
      margin: 0;
    }
    @media (max-width: $width-tablet) {
      flex-direction: column;
      p:nth-of-type(2) {
        max-width: none;
      }
    }
    @media (max-width: $width-phone) {
      margin: $section-margin-phone auto;
      padding: 24px 10px;
    }
  }
  // Section-Solution
  .section-solution {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: $section-margin auto;
    h2 {
      @include h2();
      margin: 0 0 80px;
      text-align: center;
    }
    .content-group {
      width: 100%;
      max-width: 917px;
      display: grid;
      grid-template-columns: 0.96fr 1.04fr;
      grid-template-rows: auto auto;
      grid-column-gap: 15px;
      .active-selection {
        background-color: white;
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
        img:nth-of-type(2) {
          display: block;
        }
      }
      button {
        align-self: start;
        max-width: 388px;
        padding: 16px;
        display: flex;
        align-items: center;
        cursor: pointer;
        background-color: #f9f9f9;
        border-radius: 8px;
        margin-bottom: 24px;
        h5 {
          margin: 0 auto 0 14px;
          text-align: left;
        }
        img:nth-of-type(2) {
          display: none;
        }
      }
      button:nth-of-type(1) {
        grid-column: 1;
        grid-row: 1;
      }
      button:nth-of-type(2) {
        grid-column: 1;
        grid-row: 2;
      }
      p {
        align-self: start;
        justify-self: start;
        @include body1($text-gray, 400);
        width: 100%;
        max-width: 473px;
        padding: 0 16px 0 0;
        margin: 0;
        box-sizing: border-box;
      }
      p:nth-of-type(1) {
        grid-column: 2;
        grid-row: 1;
      }
      p:nth-of-type(2) {
        grid-column: 2;
        grid-row: 2;
      }
    }
    @media (max-width: $width-tablet) {
      margin: $section-margin auto 80px;
      h2 {
        margin: 0 0 36px;
      }
      .content-group {
        button {
          width: 100%;
          margin-bottom: 0;
          img:nth-of-type(2) {
            transform: rotate(90deg);
          }
        }
        button:nth-of-type(2) {
          justify-self: end;
          grid-column: 2;
          grid-row: 1;
        }
        p {
          max-width: none;
        }
        p:nth-of-type(1) {
          grid-column: 1 / span 2;
          grid-row: 2;
          margin-top: 48px;
        }
        p:nth-of-type(2) {
          grid-column: 1 / span 2;
          grid-row: 3;
          margin-top: 20px;
        }
      }
    }
    @media (max-width: $width-phone) {
      display: none;
    }
  }
  // Section-Verticals
  .section-verticals {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: -20px auto 40px;
    padding-top: 20px;
    h2 {
      margin: 0;
      text-align: center;
      @include h2();
    }
    h2:nth-of-type(2) {
      display: none;
    }
    h4:nth-of-type(1) {
      margin: 12px 0 80px;
    }
    .content-group {
      width: 100%;
      max-width: 825px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .feature-group {
        padding: 15px;
        min-width: 235px;
        div {
          display: flex;
          img {
            width: 40px;
            height: 40px;
            margin-right: 20px;
          }
          p {
            @include p1();
          }
        }
      }
      .text-group {
        box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.2);
        border-radius: 8px;
        max-width: 395px;
        box-sizing: border-box;
        padding: 41px 24px 31px;
        h6 {
          color: $blue;
          margin: 0;
        }
        h3 {
          margin: 8px 0 24px 0;
        }
        p {
          @include body1($text-gray, 400);
          margin: 0;
        }
      }
    }
    h4:nth-of-type(2) {
      margin: 80px 10px 0;
    }
    @media (max-width: $width-tablet) {
      .content-group {
        flex-direction: row-reverse;
        .feature-group {
          padding: 10px;
        }
        .text-group {
          padding: 41px 10px 31px;
        }
      }
    }
    @media (max-width: $width-phone) {
      padding-top: 0;
      margin-bottom: 35px;
      h2:nth-of-type(1) {
        display: none;
      }
      h2:nth-of-type(2) {
        display: block;
        text-align: left;
        margin: 12px 0;
      }
      h4:nth-of-type(1) {
        margin-bottom: 32px;
      }
      .content-group {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        .feature-group {
          padding: 0;
        }
        .text-group {
          box-shadow: none;
          padding: 41px 0 0;
        }
      }
      h4:nth-of-type(2) {
        display: none;
      }
    }
  }
  // Section-Device
  .section-device {
    background-color: #000;
    margin: 0 (-$general-side-padding);
    padding: 0 $general-side-padding;
    > div {
      position: sticky;
      top: $header-height;
      z-index: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      > h1 {
        @include h1();
        color: #fff;
        padding: 40px 0 26px;
        margin: 0;
        z-index: 1;
        text-align: center;
      }
      #title-mobile {
        display: none;
      }
      #sprite {
        background: url("/device_animation.jpg") no-repeat;
        width: 800px;
        max-width: 100vw;
        height: 800px;
        margin-top: -90px;
        margin-bottom: -450px;
      }
      > img {
        height: 228px;
        width: 169px;
        margin-top: 17px;
        margin-bottom: 32px;
        display: none;
      }
      .feature-group {
        width: 100%;
        max-width: 1500px;
        height: 400px;
        overflow: hidden;
        mask-image: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 1) 20%,
          rgba(0, 0, 0, 1) 80%,
          rgba(0, 0, 0, 0) 100%
        );
        .device-feature-item {
          width: 100%;
          display: grid;
          grid-template-columns: 39% 22% 39%;
          grid-template-rows: auto;
          > h1 {
            text-align: right;
            margin: 0;
            padding: 10px 20px 0;
            @include h1-hero();
          }
          .right-group {
            grid-column: 3;
            flex-direction: column;
            align-items: flex-start;
            max-width: 300px;
            img {
              height: 32px;
              width: 32px;
              padding: 10px 20px 0;
            }
            h1 {
              display: none;
            }
            h3 {
              margin: 8px 0;
              color: #fff;
              padding: 0 20px;
            }
            p {
              margin: 0 0 47px 0;
              @include body1(#a1a1a1, 400);
              padding: 0 20px;
            }
          }
        }
      }
    }
    @media (max-width: $width-tablet) {
      > div {
        .feature-group {
          .device-feature-item {
            > h1 {
              font-size: 32px;
              line-height: 32px;
            }
          }
        }
      }
    }
    @media (max-width: $width-phone) {
      margin: 0 (-$phone-side-padding);
      padding: 0 $phone-side-padding;
    }
    @media (max-width: $minWidth-sprite),
      (max-height: $min-window-height-for-sprite) {
      > div {
        #sprite, #device-title {
          display: none;
        }
        #title-mobile {
          display: block;
        }
        > img {
          display: block;
        }
        .feature-group {
          height: auto;
          mask-image: none;
          margin-bottom: 48px;
          .device-feature-item {
            > h1 {
              display: none;
            }
            .right-group {
              grid-column: 1 / 4;
              max-width: none;
              h1 {
                display: block;
                margin: 8px 0 0;
                @include s-h1-hero();
              }
              img,
              h3,
              p {
                padding: 0;
              }
              h3 {
                margin-top: 4px;
              }
              p {
                margin-bottom: 20px;
              }
            }
          }
        }
      }
    }
  }

  // Section-Apps
  .section-apps {
    background-color: #000;
    margin: 0 (-$general-side-padding);
    display: flex;
    flex-direction: column;
    align-items: center;

    hr {
      background-color: rgba(#d8d8d8, 0.2);
      border: none;
      height: 1px;
      margin: 0;
    }
    .content-group {
      margin: 0;
      padding: 97px $general-side-padding 104px;
      box-sizing: border-box;
      width: 100%;
      max-width: $max-content-width;
      h6 {
        color: #fff;
        margin: 24px 0 40px;
      }
      h3 {
        color: #fff;
        margin: 24px 0 40px 0;
      }
      h2 {
        margin: 0 0 40px;
        @include h2();
        color: white;
      }
      p {
        @include body1(#a1a1a1, 400);
        max-width: 472px;
      }
      img {
        margin-right: 16px;
      }
      .platform {
        display: flex;
        justify-content: space-between;
        padding-bottom: 100px;
      }
      .apps {
        display: flex;
        justify-content: space-between;
      }
    }
    @media (max-width: $width-tablet) {
      .content-group {
        h2 {
          @include h2-tablet();
        }
        .platform,
        .apps {
          flex-direction: column;
        }
      }
    }
    @media (max-width: $width-phone) {
      display: none;
      margin: 0 (-$phone-side-padding);
    }
  }

  // Section-Contact
  .section-contact {
    margin: 0 (-$general-side-padding);
    background-color: #e4e4e4;
    padding: 84px $general-side-padding 64px;
    box-sizing: border-box;
    .content-group {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      .text-group {
        max-width: 320px;
        padding-right: 10%;
        h2 {
          @include h2();
          margin: 0 0 28px 0;
        }
        p {
          @include body1($text-gray, 400);
          margin-bottom: 60px;
        }
        p:nth-of-type(2) {
          display: none;
        }
      }
      form {
        max-width: 391px;
        display: flex;
        flex-direction: column;
        label {
          @include body1(#a1a1a1, 400);
          padding-left: 12px;
        }
        input, textarea {
          border: none;
          &:focus {
            border: 1px solid #4A90E2;
            outline: none;
          }
          &.has-success {
            border: none;
            outline: none;
          }
          &.has-error {
            border: 1px solid #E24A4A;
            outline: none;
          }
        }
        input {
          @include body1(#404040, 400);
          height: 40px;
          margin-bottom: 16px;
          padding: 0 12px;
        }
        textarea {
          @include body1(#404040, 400);
          height: 240px;
          resize: none;
          padding: 6px 12px;
        }
        button {
          width: 150px;
          height: 40px;
          margin: 24px 0;
          @include red-button();
          cursor: pointer;
          &:hover {
            background-color: #cc3240;
          }
          &:active {
            background-color: #992530;
          }
          &:disabled {
            background-color: rgba($red, 0.5);
          }
        }
        p {
          @include p2();
          margin: 0;
        }
      }
    }
    @media (max-width: $width-tablet) {
      padding-top: 60px;
      .content-group {
        flex-direction: column;
        .text-group {
          max-width: none;
          padding-right: 0;
          text-align: center;
          p:nth-of-type(1) {
            display: none;
          }
          p:nth-of-type(2) {
            display: block;
          }
        }
      }
    }
    @media (max-width: $width-phone) {
      margin: 0 (-$phone-side-padding);
      padding: 57px $phone-side-padding 46px;
      .content-group {
        .text-group {
          text-align: left;
          p {
            margin-bottom: 40px;
          }
          p:nth-of-type(1) {
            display: block;
          }
          p:nth-of-type(2) {
            display: none;
          }
        }
      }
    }
  }
  .captcha-bg {
    position: fixed;
    z-index: 2;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
  }
  .thanks-modal-bg {
    position: fixed;
    z-index: 10;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(black, 0.6);
    .thanks-modal {
      width: 70%;
      max-width: 494px;
      padding: 24px;
      background-color: #fff;
      box-shadow: 0 30px 44px 0 rgba(0, 0, 0, 0.5);
      display: flex;
      flex-direction: column;
      align-items: center;
      button {
        cursor: pointer;
        margin-left: auto;
        padding: 0;
        background-color: #fff;
      }
      h2 {
        @include h2();
        margin: 66px auto 16px 0;
      }
      p {
        @include body1($text-gray, 400);
        margin: 0 auto 66px 0;
      }
    }
  }
  // Footer
  footer {
    background-color: $blue;
    margin: 0 (-$general-side-padding);
    .footer-content {
      margin: 0 auto;
      padding: 32px $general-side-padding;
      box-sizing: border-box;
      max-width: 1130px;
      color: #fff;
      a {
        color: #fff;
      }
      p {
        margin: 0;
      }
      .icon-group {
        display: flex;
        justify-content: space-between;
        align-items: center;
        > img {
          margin-right: 9px;
        }
        a {
          margin-left: 13px;
        }
        p:nth-of-type(1) {
          margin-right: auto;
          font-family: $montserrat;
          font-size: 16px;
          line-height: 19px;
        }
        p:nth-of-type(2) {
          @include f2();
        }
      }
      .info-group {
        margin-top: 32px;
        display: grid;
        grid-template-columns: 1fr 1.5fr 1fr;
        grid-template-rows: 1fr auto 1fr;
        grid-template-areas:
          "left1 center1 right1"
          "left2 center2 right2"
          "copyright copyright copyright";
        p:nth-of-type(-n + 3) {
          @include f1();
        }
        p:nth-of-type(1) {
          grid-area: left1;
        }
        p:nth-of-type(2) {
          grid-area: center1;
        }
        p:nth-of-type(3) {
          grid-area: right1;
          justify-self: end;
          text-align: right;
        }
        p:nth-of-type(4) {
          @include f2();
          grid-area: copyright;
          text-align: center;
          justify-self: center;
          align-self: end;
        }
      }
    }
    @media (max-width: $width-tablet) {
      .footer-content {
        .info-group {
          grid-template-columns: auto auto 1fr;
          grid-template-areas:
            "left1 right1 right1"
            "left2 center2 right2"
            "copyright copyright copyright";
          p:nth-of-type(2) {
            grid-area: left2;
            margin-top: 16px;
          }
        }
      }
    }
    @media (max-width: $width-phone) {
  
      margin: 0 (-$phone-side-padding);
      .footer-content {
        padding: 20px $phone-side-padding 32px;
        .icon-group {
          p:nth-of-type(2) {
            display: none;
          }
        }
        .info-group {
          margin-top: 24px;
          grid-template-columns: 100%;
          grid-template-rows: auto auto auto auto;
          grid-template-areas:
            "left1"
            "left2"
            "left3"
            "copyright";
          p:nth-of-type(2) {
            align-self: center;
            margin-bottom: 16px;
          }
          p:nth-of-type(3) {
            grid-area: left3;
            justify-self: start;
            text-align: left;
          }
          p:nth-of-type(4) {
            margin-top: 48px;
          }
        }
      }
    }
  }
}
</style>
